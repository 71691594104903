import React from 'react';
import jsLogo from '../images/js-brands.svg';
import phpLogo from '../images/php-brands.svg';
import dbLogo from '../images/database-solid.svg';
import otherLogo from '../images/ellipsis-h-solid.svg';

const Portfolio = () => {
    return (
        <div id="skills" className="portfolio">
            <h2>
                Skills
                <p className="text-center m-0 fw-light fs-6"><small>I can say i’m quite good at.</small></p>
            </h2>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="card text-center pt-3 h-100 " >
                            <img src={phpLogo} class="card-img-top php" alt="php"/>
                            <div className="card-body">
                                <h5 class="card-title">PHP</h5>
                                <p className="card-text"></p>
                                <p className="card-text">Laravel <br/>
                                CakePHP
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card text-center pt-3 h-100" >
                            <img src={jsLogo} class="card-img-top js" alt="javascript"/>
                            <div className="card-body">
                                <h5 class="card-title">JavaScript</h5>
                                <p className="card-text">
                                    ReactJS
                                    <br/>
                                    NodeJS
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card text-center pt-3 h-100" >
                            <img src={dbLogo} className="card-img-top db" alt="other"/>
                            <div className="card-body">
                                <h5 class="card-title">Database</h5>
                                <p className="card-text">
                                    MySql
                                    <br/>
                                    MongoDB
                                    <br/>
                                    Firebase
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card text-center pt-3 h-100">
                            <img src={otherLogo} className="card-img-top other" alt="other"/>
                            <div className="card-body">
                                <h5 class="card-title">Other</h5>
                                <p className="card-text">
                                    Continuous integration
                                    <br/>
                                    TDD
                                    <br/>
                                    HTML5/CSS3
                                    <br/>
                                    Bootstrap
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Portfolio;
