import React from 'react';
import ME from '../images/cananakin.JPG';

const AboutMe = () => {
    return (
        <div id="about" className="about">
            <h2>
                About Me <br/>
                <small>A small introduction about myself</small>
            </h2>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="card mb-3 right" >
                            <div className="row g-0">
                                <div className="col-md-8 order-2 order-md-1">
                                    <div className="card-body">
                                        <h5 className="card-title">Canan Akin</h5>
                                        <h5 className="card-sub-title">Front-End Web Engineer</h5>
                                        <p className="card-text">
                                            5+ years of experience in web applications. 
                                            <br /><br />
                                            Started as a php developer and moved onto JavaScript over the course of 3 years. 
                                            <br/><br/>Worked on various web projects using ReactJS, Bootstrap and mostly VanillaJS (as we all know what this means). 
                                            <br/><br/>Have excellent knowledge of HTML5 and SCSS. Worked in Agile teams.
                                            <br/><br />

                                            Strong engineering professional with a Degree focused in Web Development from Universite of Zirve.
                                            <br />
                                            My favourite frontend : ReactJS, ReactNative with continuous integration on Jira.
                                            </p>
                                            <div className="d-grid col-md-3">
                                            <a href="mailto:e.cananakin@gmail.com" class="btn btn-danger btn-lg">Contact Me</a>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-md-4 text-center order-1 order-md-2">
                                    <img src={ME} alt="Canan Akin" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutMe;
