import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import reactIcon from '../images/react-brands.svg';
import laravelIcon from '../images/laravel-brands.svg';
import magenteIcon from '../images/magento-brands.svg';
import shopifyIcon from '../images/shopify-brands.svg';
import phpIcon from '../images/php-brands.svg';

const Projects = () => {
    
    const ReactIcon = () => (<img src={reactIcon} alt="ReactJS"/>);
    const MagentoIcon = () => (<img src={magenteIcon} alt="Magento Development" />);
    const LaravelIcon = () => (<img src={laravelIcon} alt="Laravel Php Framework" />);
    const ShopifyIcon = () => (<img src={shopifyIcon} alt="Shopify" />);
    const PhpIcon = () => (<img src={phpIcon} alt="PHP (CakePHP/Lavavel Framework)" />);
    
    return (
        <div id="projects" className="projects">
            <h2>
                Projects
                <p className="text-center m-0 fw-light fs-6">Showcase of my latest works, projects and developments</p>
            </h2>
            
            <VerticalTimeline>
                
                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top react"
                    iconStyle={{ background: 'rgb(97, 218, 251)', color: '#fff', padding: '7px' }}
                    icon={<ReactIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">ReactJS</span>
                        <span className="badge rounded-pill bg-secondary">Redux+Saga</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Real Estate Office Management Software</h3>
                    <h4 className="vertical-timeline-element-subtitle">SaaS / CRM</h4>
                    <p>
                    Built using ReactJS with Redux and Isomorphic with Laravel API interface.
                    </p>
                    <a href="https://app.emlakofisimiyonet.com/" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top php"
                    iconStyle={{ background: 'rgb(97, 129, 182)', color: '#fff', padding: '10px 5px' }}
                    icon={<PhpIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">CakePHP</span>
                        <span className="badge rounded-pill bg-secondary">MySql</span>
                        <span className="badge rounded-pill bg-secondary">jQuery</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Emlakbuluyoruz.Com</h3>
                    <h4 className="vertical-timeline-element-subtitle">Real Estate Portal</h4>
                    <p>Built Public UI and Admin panel.</p>
                    <a href="https://www.emlakbuluyoruz.com" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top react"
                    iconStyle={{ background: 'rgb(97, 218, 251)', color: '#fff', padding: '7px' }}
                    icon={<ReactIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">React-Native</span>
                        <span className="badge rounded-pill bg-secondary">Laravel-API</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Emlakbuluyoruz App</h3>
                    <h4 className="vertical-timeline-element-subtitle">Mobile Application</h4>
                    <p>
                    Develop IOS and Android application using React-Native, with using Laravel API Backend.
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top magento"
                    iconStyle={{ background: 'rgb(244, 111, 37)', color: '#fff', padding: '9px 13px' }}
                    icon={<MagentoIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">PHP</span>
                        <span className="badge rounded-pill bg-secondary">Magento 2</span>
                        <span className="badge rounded-pill bg-secondary">Docker</span>
                        <span className="badge rounded-pill bg-secondary">Multi-Language</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Abacionline.Com</h3>
                    <h4 className="vertical-timeline-element-subtitle">Fashion E-commerce</h4>
                    <p>Working on Magento2 UI upgrade and enhancements using Magento UI.</p>
                    <a href="https://www.abacionline.com" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>
                
                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top php"
                    iconStyle={{ background: 'rgb(97, 129, 182)', color: '#fff', padding: '10px 5px' }}
                    icon={<PhpIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">CakePHP</span>
                        <span className="badge rounded-pill bg-secondary">MySql</span>
                        <span className="badge rounded-pill bg-secondary">jQuery</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Arababuluyoruz.Com</h3>
                    <h4 className="vertical-timeline-element-subtitle">Car Sale & Rent Portal</h4>
                    <p> Built Public UI and Admin panel. </p>
                    <a href="https://www.arababuluyoruz.com" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>
                
                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top magento"
                    iconStyle={{ background: 'rgb(244, 111, 37)', color: '#fff', padding: '9px 13px' }}
                    icon={<MagentoIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">PHP</span>
                        <span className="badge rounded-pill bg-secondary">Magento 2</span>
                        <span className="badge rounded-pill bg-secondary">Docker</span>
                        <span className="badge rounded-pill bg-secondary">Linnworks</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Transitparts</h3>
                    <h4 className="vertical-timeline-element-subtitle">Transit Parts E-commerce</h4>
                    <p>
                    Working on Magento2 UI upgrade and enhancements using Magento UI and Linnworks Configure.
                    </p>
                    <a href="https://www.transitparts.co.uk" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>
                
                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top laravel"
                    iconStyle={{ background: '#F05340', color: '#fff', padding: '10px' }}
                    icon={<LaravelIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">Laravel</span>
                        <span className="badge rounded-pill bg-secondary">MySql</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Municipality Content Management</h3>
                    <h4 className="vertical-timeline-element-subtitle">Nizip Council/Municipality</h4>
                    <p>
                    Built using Laravel along with Bootstrap JS Framework.
                    </p>
                    <a href="https://www.nizip.bel.tr" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>
                
                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top shopify"
                    iconStyle={{ background: '#96bf48', color: '#fff', padding: '6px 12px' }}
                    icon={<ShopifyIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">PHP</span>
                        <span className="badge rounded-pill bg-secondary">Shopify</span>
                        <span className="badge rounded-pill bg-secondary">Amazon - Ebay</span>
                        <span className="badge rounded-pill bg-secondary">Linnworks</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">ToptenWholeSales</h3>
                    <h4 className="vertical-timeline-element-subtitle">Online Sales</h4>
                    <p>
                    Develop on Shopify, Linnworks Configuration, Amazon and Ebay Listing, Social Media Ads, Marketing.
                    </p>
                    <a href="https://www.toptenwholesales.com" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>
                
                <VerticalTimelineElement
                    className="vertical-timeline-element--pr top laravel"
                    iconStyle={{ background: '#F05340', color: '#fff', padding: '10px' }}
                    icon={<LaravelIcon />}
                >
                    <div class="ResumeProjectsBlock-technologies">
                        <span className="badge rounded-pill bg-secondary">Laravel</span>
                        <span className="badge rounded-pill bg-secondary">MySql</span>
                        <span className="badge rounded-pill bg-secondary">Bootstrap</span>
                    </div>
                    <h3 className="vertical-timeline-element-title">Municipality Content Management</h3>
                    <h4 className="vertical-timeline-element-subtitle">Islahiye Council/Municipality</h4>
                    <p>
                    Built using Laravel along with Bootstrap JS Framework.
                    </p>
                    <a href="https://www.islahiye.bel.tr" class="btn btn-outline-secondary" rel="noreferrer" target="_blank">View Website</a>
                </VerticalTimelineElement>
            </VerticalTimeline>
            
        </div>
    )
}

export default Projects;
