import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TopNavbar from './components/TopNavbar';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Particles from 'react-particles-js';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Portfolio from './components/Portfolio';
import Experience from './components/Experience';
import Tools from './components/Tools';

function App() {
  return (
    <>
      <Particles 
        name="home"
        className="particles-canvas"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 900
              }
            },
            shape: {
              type: "circle",
              stroke: {
                width: 6,
                color: '#f9ab00'
              }
            }
          }
      }}/>
      <TopNavbar />
      <Header />
      <AboutMe/>
      <Experience />
      <Portfolio />
      <Tools />
      <Projects />
      <Navbar/>
    </>
  );
}

export default App;
