import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faGraduationCap, faLaptopCode, faTools, faEnvelope, faSmile } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    
    const linkProps = { 
        className:"nav-link text-center",
        activeClass:"active",
        spy:true
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-bottom">
            <div className="container">
                 <div className=" navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-2 mb-lg-0" style={{justifyContent: 'space-evenly', flex: 1 }}>
                        <li className="nav-item">
                            <Link to="home" {...linkProps}>
                                <FontAwesomeIcon icon={faHome} />
                                <br/>
                                <span>Home</span> 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="about" {...linkProps} >
                                <FontAwesomeIcon icon={faSmile} />
                                <br/> 
                                <span>about me</span> 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="experience" {...linkProps}>
                                <FontAwesomeIcon icon={faBriefcase} />
                                <br/>
                                <span>experience</span> 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="education" {...linkProps} >
                                <FontAwesomeIcon icon={faGraduationCap}/>
                                <br/>
                                <span>education</span> 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="skills" {...linkProps} >
                                <FontAwesomeIcon icon={faTools}/> <br/> <span>skills</span> 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="projects" {...linkProps}>
                                <FontAwesomeIcon icon={faLaptopCode} /> <br/> <span>projects</span> 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="mailto:e.cananakin@gmail.com" {...linkProps}>
                                <FontAwesomeIcon icon={faEnvelope} />  <br/> <span>Hire me</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
