import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopHouse, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

const Experience = () => {
    const WorkIcon = () => (<FontAwesomeIcon icon={faLaptopHouse} size="lg"  style={{ color: '#fff'}}/>);
    const SchoolIcon = () => (<FontAwesomeIcon icon={faGraduationCap} size="lg"  style={{ color: '#fff'}}/>);
    
    return (
        <div id="experience" className="experience">
            <h2>
                Work Experience & Education
                <p className="text-center m-0 fw-light fs-6">My previous jobs and my qualifications.</p>
            </h2>
            
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Nov 2019 - present"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Frontend Web Wngineer / Director @Janansoft</h3>
                    <h4 className="vertical-timeline-element-subtitle">London, UK</h4>
                    <p>
                    Frontend: ReactJS|Laravel
                    <br/>
                    Backend: Laravel | MySql | MongoDB | NodeJS | Firebase
                    <br/>
                    E-commerce: Magento 2 | Shopify | Woo-Commerce
                    <br/>
                    Mobile: React-Native
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Dec 2014 - Aug 2019"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Full Stack Developer at Finders Yazilim</h3>
                    <h4 className="vertical-timeline-element-subtitle">Gaziantep, Turkey</h4>
                    <p>
                    PHP/MySql/Javascript Development/SEO/Mobile Development
                    <br/>
                    CakePHP | Laravel 5 | ReactJS | React-Native
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Jun 2014 - Dec 2014"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Junior Web Developer, @Finders</h3>
                    <h4 className="vertical-timeline-element-subtitle">Gaziantep, Turkey</h4>
                    <p>
                    User Experience, Web Mobile Development
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Jun 2013 - Aug 2013"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Programming Intership, @TurkTelecom</h3>
                    <h4 className="vertical-timeline-element-subtitle">Gaziantep, Turkey</h4>
                    <p>
                    Worked with various internal applications such as Intranet SPAs purposefully built for Turk Telecom.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    id="education"
                    className="vertical-timeline-element--education"
                    date="2009 - 2014"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Computer Engineering</h3>
                    <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                    <p>
                    Bachelor Degree
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}

export default Experience;
