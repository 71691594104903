import React from 'react';
import linkedinIcon from '../images/linkedin-brands.svg';
import githubIcon from '../images/github-brands.svg';

const TopNavbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark d-none d-sm-block" style={{ position: 'absolute', width: '100%', zIndex: 2}}>
            <div className="container">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a href="mailto:e.cananakin@gmail.com" className="nav-link"  >e.cananakin@gmail.com</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.linkedin.com/in/canan-akin" rel="noreferrer" target="_blank" className="nav-link" >
                                <img width="20" src={linkedinIcon} alt="LinkedIn" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://github.com/cananakin" rel="noreferrer" target="_blank" className="nav-link" >
                                <img width="20" src={githubIcon} alt="Github" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default TopNavbar;
