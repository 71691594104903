import React from 'react';
import Typed from 'react-typed';

import linkedinIcon from '../images/linkedin-brands.svg';
import githubIcon from '../images/github-brands.svg';

const Header = () => {
    return (
        <div className="header-wraper" id="home">
            <div className="main-info">
                <div className="title">
                    <h1>I'm Canan Akin</h1>
                    <Typed 
                        className="typed-text" 
                        strings={['Frontend Web Engineer', 'Director of Janansoft']}
                        typeSpeed={40}
                        backSpeed={60}
                        loop
                    />
                    <div className="row justify-content-center d-block d-sm-none">
                        <div className="col-12">
                            <a href="mailto:e.cananakin@gmail.com" className="nav-link" style={{ color: '#fff'}} >e.cananakin@gmail.com</a>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="col-2">
                                <a href="https://www.linkedin.com/in/canan-akin" rel="noreferrer" target="_blank" className="nav-link"  >
                                    <img width="20" src={linkedinIcon} alt="LinkedIn" />
                                </a>
                            </div>
                            <div className="col-2">
                                <a href="https://github.com/cananakin" rel="noreferrer" target="_blank" className="nav-link" >
                                    <img width="20" src={githubIcon} alt="Github" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
