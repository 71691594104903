import React from 'react';

const Tools = () => {
    return (
        <div id="tools" className="tools"> 
            <h2>
                Tools
                <p className="text-center m-0 fw-light fs-6"><small>My favorites tools</small></p>
            </h2>
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <p>
                            Operating system: MacOS(over ten years)
                            <br/>IDE: Visual Studio Code
                            <br/>Containerization: Docker / Rancher
                            <br/>Continuous integration software: Jenkins
                            <br/>Project Management Software: Jira
                            <br/>Version control system software: Github / Gitlab
                            <br/>
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Tools;
